import { LoadingLine } from '@jetshop/ui/Loading/LoadingLine';
import { styled } from 'linaria/react';
import React from 'react';
import UIMaxWidth from '../../components/Layout/MaxWidth';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import ContentBlock from '../../blocks/ContentBlock/ContentBlock';
import ContentBlockV2 from '../../blocks/ContentBlockV2/ContentBlockV2';
import CategoryProducts from '../../blocks/CategoryProducts/CategoryProducts';
import CategoryProductsSmall from '../../blocks/CategoryProductsSmall/CategoryProductsSmall';
import HelloRetailProductList from '../../blocks/HelloRetail/HelloRetailProductList.js';
import HelloRetailProductListSmall from '../../blocks/HelloRetail/HelloRetailProductListSmall.js';
import InfoBlock from '../../blocks/InfoBlock/InfoBlock';
import ImageBlock from '../../blocks/ImageBlock/ImageBlock';
import Heading from '../../components/ui/Heading';
import InstagramGallery from '../../blocks/InstagramGallery/InstagramGallery';
import ArticleBlock from '../../blocks/ArticleBlock/ArticleBlock';

const Header = styled(Heading)`
  font-size: 1.6875rem;
  font-weight: 600;
  margin: 1rem 0 3rem;
`;

const Content = styled('div')`
  padding-bottom: 1rem;

  p {
    margin-bottom: 1rem;
  }
`;

const MaxWidth = styled(UIMaxWidth)`
  max-width: 60rem;
`;

const components = {
  CONTENTBLOCK: ContentBlock,
  CONTENTBLOCKV2: ContentBlockV2,
  CATEGORYITEM: 'categoryItem',
  PRODUCTLISTING: CategoryProducts,
  PRODUCTLISTINGSMALL: CategoryProductsSmall,
  IMAGEBLOCK: ImageBlock,
  INFOBLOCK: InfoBlock,
  INFOBLOCKHTML: InfoBlock,
  INFOBLOCKHTML_NEW: InfoBlock,
  HELLORETAILPRODUCTLIST: HelloRetailProductList,
  HELLORETAILPRODUCTLISTSMALL: HelloRetailProductListSmall,
  INSTAGRAMGALLERY: InstagramGallery,
  ARTICLEBLOCK: ArticleBlock,
  ARTICLEINGRESSA: 'articleIngressA',
  ARTICLEINGRESSB: 'articleIngressB',
  ARTICLEINGRESSC: 'articleIngressC',
  ARTICLEINGRESSSEOSE: 'articleIngressSEOSE',
  ARTICLEINGRESSSEONO: 'articleIngressSEONO',
  ARTICLEINGRESSSEOFI: 'articleIngressSEOFI',
  ARTICLEINGRESSSEODK: 'articleIngressSEODK',
  HTML: props => {
    return (
      <Content
        dangerouslySetInnerHTML={{
          __html: props.html.value.value
        }}
      />
    );
  }
};

function ContentPage(props) {
  const { page } = props;

  return (
    <>
      <Header level="1">
        <MaxWidth>
          {page ? page.name : <LoadingLine heightPx={27} widthRem={15} />}
        </MaxWidth>
      </Header>
      {page ? (
        <>
          {page.data?.items?.length > 0 ? (
            <ContentRenderer items={page.data.items} components={components} />
          ) : (
            <Content
              dangerouslySetInnerHTML={{
                __html: page.content
              }}
            />
          )}
        </>
      ) : (
        <LoadingLine
          heightPx={14}
          widthRem={30}
          randomizeWidthBy={10}
          count={10}
          style={{
            marginBottom: '12px'
          }}
        />
      )}
    </>
  );
}

export default ContentPage;
