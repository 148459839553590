import StartPageRenderer from '@jetshop/ui/StartPageRenderer';
import React from 'react';
import { useQuery } from 'react-apollo';
import LoadingPage from '../LoadingPage';
import startPageQuery from '../../gql/queries/StartPageQuery.gql';
import HeroBlock from '../../blocks/HeroBlock/HeroBlock';
import CategoryBlock from '../../blocks/CategoryBlock/CategoryBlock';
import CategoryButtons from '../../blocks/CategoryButtons/CategoryButtons';
import IconsBlock from '../../blocks/IconsBlock/IconsBlock';
import ContentBlock from '../../blocks/ContentBlock/ContentBlock';
import ContentBlockV2 from '../../blocks/ContentBlockV2/ContentBlockV2';
import CategoryProducts from '../../blocks/CategoryProducts/CategoryProducts';
import CategoryProductsSmall from '../../blocks/CategoryProductsSmall/CategoryProductsSmall';
import InfoBlock from '../../blocks/InfoBlock/InfoBlock';
import HelloRetailProductList from '../../blocks/HelloRetail/HelloRetailProductList.js';
import HelloRetailProductListSmall from '../../blocks/HelloRetail/HelloRetailProductListSmall.js';
import InstagramGallery from '../../blocks/InstagramGallery/InstagramGallery';

const startPageComponents = {
  HEROBLOCK: HeroBlock,
  HEROITEM: 'heroItem',
  CATEGORYBLOCK: CategoryBlock,
  CATEGORYITEM: 'categoryItem',
  CATEGORYBUTTONS: CategoryButtons,
  CATEGORYBUTTON: 'categoryButton',
  ICONSBLOCK: IconsBlock,
  ICONSITEM: 'iconsItem',
  CONTENTBLOCK: ContentBlock,
  CONTENTBLOCKV2: ContentBlockV2,
  INFOBLOCKHTML_NEW: InfoBlock,
  HELLORETAILPRODUCTLIST: HelloRetailProductList,
  HELLORETAILPRODUCTLISTSMALL: HelloRetailProductListSmall,
  CONTENTBLOCKITEM: 'contentBlockItem',
  CONTENTBLOCKITEMV2: 'contentBlockItemV2',
  PRODUCTLISTING: CategoryProducts,
  PRODUCTLISTINGSMALL: CategoryProductsSmall,
  INSTAGRAMGALLERY: InstagramGallery
};

const StartPage = ({ startPageId }) => {
  const result = useQuery(startPageQuery, {
    variables: { startPageId: startPageId }
  });
  const { loading } = result;

  if (loading || !result?.data) return <LoadingPage />;

  return (
    <StartPageRenderer
      result={result}
      startPageComponents={startPageComponents}
      LoadingPage={LoadingPage}
    />
  );
};

export default StartPage;
